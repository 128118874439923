.post {
    margin-bottom: 1rem;
    box-shadow: var(--DropShadow);
    display: flex;
    flex-direction: column;
}

.post::-webkit-scrollbar {
    display: none;
}
.post {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.post {
    max-width: 100%;
}

.topBar {
    padding: 0.25rem  0.75rem;
    background-color: var(--PrimaryRed);
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.timestamp p {
    margin: 0;
}

.rightTopBar {
    display: flex;
    align-items: center;
}

.post h3 {
    margin: 0;
}
.post h4 {
    margin: 0;
    font-weight: 500;
}
.post p {
    margin: 0;
    overflow-wrap: break-word;
    word-break: break-word;
}
.post button {
    margin: 0;
    cursor: pointer;
}

/* Profile Line */
.profileLine {
    display: flex;
    align-items: center;

}

.profileLine h3 {
    margin-left: 0.5rem;
}
.profileLine p {
    margin-left: 0.75rem;
    margin-top: 0.2rem;
    font-size: 1rem;
}

.profilePhoto {
    object-fit: contain;
    width: 45px;
    height: 45px;
    background-color: var(--LightGray);
    border-radius: 50%;
    box-shadow: var(--DropShadow);
}

/* Options Box */
.icon {
    height: 30px;
}

.optionsBox {
    position: absolute;
    z-index: 1;
    top: 3.5rem;
    right: 1rem;
    border: none;
    background-color: var(--DarkGray);
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.optionsBox button {
    color: white;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    text-align: left;
}
.confirmBox {
    position: absolute;
    z-index: 2;
    background-color: var(--LightGray);
    color: var(--DarkGray);
    left: 12.5%;
    top: 5rem;
    width: 70%;
    text-align: center;
    padding: 0.75rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}
.confirmBox h4 {
    font-size: 1.3rem;
    font-weight: 400;
}

.confirmBox button {
    padding: 0.5rem 1rem;
    text-align: center;
    border-radius: 10px;
    margin: 15px 10px;
    color: white;
    font-size: 1rem;
}

.confirmBox .yes  {
    background-color: var(--OkGreen)
}

.confirmBox .no {
    background-color: rgb(238, 75, 75);
}

/* Bottom Bar */
.bottomBar {
    padding: 0.25rem  0.75rem;
    background-color: var(--LightGray);
    border-radius: 0 0 10px 10px;
    color: var(--DarkGray)
}

.infoBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttonBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.buttonBlock img {
    width: 25px;
}

.buttonBlock button {
    padding: 0;
    margin-right:  15px;
    cursor: pointer;
}

.buttonBlockUser {
    cursor: default
}

.likesTimeBookmark {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.likesTimeBookmark p {
    font-size: 1rem;
    font-weight: 600;
}
.likesAndDate {
    display: flex;
    align-items: center;
}

.likesAndDate button {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
}
.likesAndDate p {
    font: 1rem sans-serif;
    color: var(--DarkGray);
}

.bookmarkBtn {
    size: 15px;
}

.infoBar p {
    font-weight: 700;
}

.dataBlock b {
    font-size: 1.2rem;
}
.dataBlock p {
    margin-bottom: 0.5rem;
}

/* Post Comment Form */
.dataBlock form {
    display: flex;

    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;

    padding: 1rem;
    background-color: var(--LightGray);
    box-shadow: var(--DropShadow);
    border-radius: 15px;
    margin-bottom: 0.25rem;
}

.datePosted {
    padding-bottom: 1rem;
    color: #424141bb;
}

.commentForm input, textarea {
    box-shadow: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow-y: hidden;
}

.commentForm textarea {
    flex-grow: 2;
    height: 25px;
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    border-radius: 0px;
    resize:none;
}

.commentForm input[type="submit"] {
    flex-grow: 1;
    background: none;
    color: var(--DarkGray)
}

.commentForm input[type="image"] {
    height: 30px;
    margin-left: 10px;
}
.commentsBlock {
    display: flex;
    flex-direction: column;
}
/* Comment BOX */
.commentBox {
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.dataBlock button, .dataBlock a {
    color: var(--DarkGray);
    display: inline-block;
}
.dataBlock button {
    text-align: left;
}

.commentBox p {
    margin: 0;
    display: inline-block;
    font-size: 1rem;
}

.commentBox b {
    font-size: 1rem;
}

.loadNextButton {
    height: 45px;
    cursor: pointer;
}

@media only screen and (min-width: 801px) {
    .buttonBlock img {
        width: 35px;
    }
    .infoBar p {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

@media only screen and (max-width: 1028px) { 
    .post {
        padding-bottom: 0rem;
    }
}

/* Animation */

@keyframes slideInFromBottom {
  from { transform: translateY(50%)}
  to { transform: translateY(0)}
}
@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.pinImage {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    margin-left: 5px;
}
.postPage {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .postPage {
        width: 100%!important;
        box-sizing: border-box;
        padding: 0 1rem;
    }    
}

.smallText {
    font-size: 0.8rem!important;
    color: var(--DarkGray);
}

/* MODAL */
.modalContainer {
    position: fixed;
    z-index: 80;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000a1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto; /* changed from none to auto */
}

.modalContent {
    background-color: var(--LightGray);
    color: var(--DarkGray);
    border-radius: 10px;
    padding: 1rem;
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    box-shadow: var(--DropShadow);
    z-index: 100;
}

.modalContent .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.modalContent .form input {
    border: var(--DarkGray) 1px solid;
}

.modalContent button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    background: var(--DarkGray);
}

.loadingBox {
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #252525;
    flex-direction: column;
}