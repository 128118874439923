.modal {
    display: block; /* Hidden by default */
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(0, 0, 0, 0.62); 
    overflow-y: hidden;
}

.content {
    height: 100vh;
    margin: auto;
    border-radius: 20px;
    width: fit-content;
    max-width: 90%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.content span {
    position: absolute;
    z-index: 1000;
    top: 35%;
    right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    padding: 1rem;
    margin: -1rem;
}
.nextButton {
    position: absolute;
    color: white;
    box-sizing: border-box;
    right: -5px;
    top: 47%;
    cursor: pointer;
    height: 50px;
    width: 50px;
}
.prevButton {
    position: absolute;
    color: white;
    box-sizing: border-box;
    left: -5px;
    top: 47%;
    cursor: pointer;
    height: 50px;
    width: 50px;
}
.content img {
    margin: auto;
    max-width: 90vw;
    border-radius: 15px;
    box-shadow: var(--DropShadow);
    max-height: 80vh;
}
.postImage {
    max-height: 75vh;
    max-width: 95vw;
}
@media screen and (min-width: 768px) {
    .content img {
        scale: 1.05;
    }
}
@media screen and (max-width: 768px) {
    .content {
        width: 90%;
    }
}