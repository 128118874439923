.feedParent {
    display: grid;
    grid-template-columns: 1fr 600px 1fr;
}
.feedDiv {
    width: 600px!important;
    margin: auto!important;
    position: relative;
    padding-bottom: 2rem;
}

.feedDiv.modal-open {
    height: 100vh;
    overflow-y: hidden;
}

.loading {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 10%;
}

.pullToRefresh {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}
.bottomRef {
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: 200px;
    z-index: -1000;
}

.bottomText {
    text-align: center;
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 2.5rem;
}
.feedAndLeftFrame {
    display: flex;
}


@media only screen and (max-width: 768px) {
    .feedParent {
        display: grid;
        grid-template-columns: auto;
        padding: 0 3%!important;
    }
    .feedDiv {
        width: 100%!important;
        box-sizing: border-box;
    }
    .feedAndLeftFrame {
        flex-direction: column;
    }
    
}


