.bottomNavbar {
    background-color: var(--DarkGray);
    box-shadow: 0 2px 8px 1px #2e2e2e;
    overflow: hidden;
    position: -webkit-fixed;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 7.5px;
}
.bottomNavbar img {
    height: 45px;
}

@media screen and (min-width: 768px) {
    .bottomNavbar {
        display: none;
    }
}