.loginModal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.loginModalContent {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 800px) {
    .loginModalContent {
        width: 90%;
        box-sizing: border-box;
    }
}

/* Form Stuff */
input {
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
    0 2px 2px rgba(0,0,0,0.05), 
    0 4px 4px rgba(0,0,0,0.05), 
    0 8px 8px rgba(0,0,0,0.05),
    0 16px 16px rgba(0, 0, 0, 0.05);
}

form a {
    color:#7a7a7a;
    font-weight: 500;
}

.loginModalContent input[type="submit"] {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
}

.closeButton {
    text-align: right;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
}

.topBar {
display: grid;
grid-template-columns: auto auto auto;
}

.closeButton {
    background: none;
    width: fit-content;
    margin-left: auto; 
    margin-right: 0;
    border: none;
    cursor: pointer;
    color: #7a7a7a
}

.error {
    color: #cf4141;
    margin-top: 0rem;
    font-weight: 600;
}