.landingDiv {
    height: 100%;
    width: 100%;
}

.landingContent {
    margin: 0 5rem;
}


.loginButton {
    background-color: var(--PrimaryRed);
    color: white;
    border-radius: 10px;
    padding: 0.75em 1.5rem;
    font-size: 1rem;
    font-weight: 600;
}

@media only screen and (max-width: 800px) {
    .landingContent {
        margin: 10px 5%;
    }
}


/* Nav */
.landingDiv {
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-image: url(https://xcaliberinternational.com/wp-content/uploads/2017/04/hero-home.jpg);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
}
.nav {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.nav img {
    width: 279px;
    height: 100%;
}

.contentBox {
    display: flex;
    justify-content: center;
    margin-right: 25%;
    flex-direction: column;
    align-content: space-around;
    align-items: flex-end;
}

@media screen and (max-width: 900px) {
    .landingDiv {
        background-color: rgba(0, 0, 0, 0);
        background-repeat: no-repeat;
        background-image: url(https://xcaliberinternational.com/wp-content/uploads/2017/04/hero-home.jpg);
        background-size: cpver;
        background-position: center center;
        width: 100%;
        height: 100vh;
    }
    
    .contentBox {
        display: flex;
        justify-content: center;
        margin-right: 0;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
}
/* Form Stuff */
.landingDiv form {
    background-color: var(--LightGray);
    padding: 1rem 2rem;
    border-radius: 25px;
    box-shadow: var(--DropShadow);
    min-width: 300px;
    margin-top: 2rem;
}

.landingDiv input {
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
    0 2px 2px rgba(0,0,0,0.05), 
    0 4px 4px rgba(0,0,0,0.05), 
    0 8px 8px rgba(0,0,0,0.05),
    0 16px 16px rgba(0, 0, 0, 0.05);
}

.landingDiv form a {
    color:#7a7a7a;
    font-weight: 500;
}

.landingDiv form input[type="submit"] {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: rgb(203, 3, 3)!important;
}

.error {
    color: #cf4141;
    margin-top: 0rem;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .landingDiv {
        background-image: none!important;
    }
}

.forgot {
    cursor: pointer;
    color: #7a7a7a;
    text-decoration: underline;
}