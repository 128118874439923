.editModal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.editModalContent {
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    color:var(--DarkGray);
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    width: 350px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
}

.editModalContent form textarea {
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    height: 9rem;
}

.editModalContent form input[type="submit"]:first-of-type {
    margin-right: 1rem;
}

.editModalContent .saveBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.cancel {
    background-color: #d13a3a!important;
}

@media only screen and (max-width: 800px) {
    .editModalContent {
        width: 85%;
        box-sizing: border-box;
    }
}