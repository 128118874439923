body {
  --DarkGray: #1E1E1E;
  --PrimaryRed: #7d7562;
  --OkGreen: #33BB5C;
  --LightGray: #E5E5E5;
  --DropShadow:  0 1px 1px rgba(0,0,0,0.05), 
    0 2px 2px rgba(0,0,0,0.05), 
    0 4px 4px rgba(0,0,0,0.05), 
    0 8px 8px rgba(0,0,0,0.05),
    0 16px 16px rgba(0, 0, 0, 0.05);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--DarkGray);
  color: white;
  font-family: acumin-pro,sans-serif;
}

.ptr, .ptr__children {
  overflow-y: hidden!important;
}
.lds-ellipsis {
  display: flex;
  justify-content: center;
}

.lds-ellipsis div {
  background-color: #E5E5E5!important;
}

/* Standard Components */
form {
  display: flex;
  flex-direction: column;
  color: var(--DarkGray);
}

input, textarea, select {
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin: 0;
  margin-top: 0.2rem;
  margin-bottom: 1.2rem;
  display: inline-block;
  border:none;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: var(--LightGray);
  font-weight: 500;
  font-size: 1rem;
  font-family: acumin-pro,sans-serif;
  resize: none;
}

input:focus-visible, textarea:focus-visible, select:focus-visible {
  outline: none;
}

input[type=file]::file-selector-button {
  border: 2px solid var(--DarkGray);
  padding: 0.5rem 1rem;
  background: none;
  border-radius: 10px;
}

input[type=submit] {
  width: 100%;
  background-color: var(--OkGreen);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=submit]:hover {
  background-color: #33aa57
}

label {
  font-size: 1.2rem;
  font-weight: 700;
  font-style: normal;
}

form h3 {
  color: var(--PrimaryRed);
  font-weight: 700;
  font-style: normal;
  font-size: 2.4rem;
  text-align: center;
  margin: 0;
  line-height: 2rem;
  margin-bottom: 0.75rem;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.active {
  background: #ca0303!important;
}

.main-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.loading-spinner {
  animation: spin 1s linear infinite;
  transform-origin: center;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}