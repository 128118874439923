.commentDiv {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    overflow-y: auto;
    scrollbar-color: var(--PrimaryRed);
    scrollbar-width: 3px;
}

.commentBox {
    display: inline-block;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.commentBox p {
    margin: 0;
    display: inline-block;
    font-size: 1rem;
}
.commentBox b {
    font-size: 1rem;
}
.commentDiv .loadNextButton {
    margin:auto;
    text-align: center;
}
.loadNextButton img {
    height: 45px;
    cursor: pointer;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}

.commentDiv::-webkit-scrollbar {
    -webkit-appearance: none;
}

.commentDiv::-webkit-scrollbar:vertical {
    width: 7px;
}

.commentDiv::-webkit-scrollbar:horizontal {
    height: 7px;
}

.commentDiv::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
}

.commentForm {
    display: flex;

    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;

    padding: 1rem;
    background-color: var(--LightGray);
    box-shadow: var(--DropShadow);
    border-radius: 15px;
    margin-bottom: 1rem;
}

.commentForm input, textarea {
    box-shadow: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow-y: hidden;
}

.commentForm textarea {
    flex-grow: 2;
    height: 25px;
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    border-radius: 0px;
    resize:none;
}

.commentForm input[type="submit"] {
    flex-grow: 1;
    background: none;
    color: var(--DarkGray)
}

.commentForm input[type="image"] {
    height: 30px;
    margin-left: 10px;
}

.commentText a {
    color: #2b8eff;
    text-decoration: none;
}