.navBar {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5rem;
}

.logo {
    max-height: 10rem;
}

.desktopButtons {
    display: flex;
    align-items: center;
}

.desktopButtons .notLoggedIn button {
    color: white;
    font-size: 1.3rem;
    border: white 3px solid;
    border-radius: 25px;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
}

.navBar .desktopButtons .helpButton {
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 3rem;
}

.navBar .desktopButtons .createPostButton {
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.desktopButtons img {
    height: 48px;
}


.navBar .desktopButtons .logoutButton {
    background-color: white;
    color: var(--PrimaryRed);
    padding: 0.75rem 2rem;
    border-radius: 5rem;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 2rem;
    cursor: pointer;
    -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
    -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
    box-shadow: rgba(0,0,0,0.8) 0 0 10px;
}

.navBar .hamburgerIcon {
    max-width: 35px;
    max-height: 35px;
}

.hamburgerIconButton {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

.hamburgerMenu {
    background-color: none;
    width: fit-content;
    margin-left: auto;
    margin-right: 5%;
    flex-direction: column;
    display: none;
    position: absolute;
    top: 5rem;
    right: 0rem;
    width: 40%;
    z-index: 1;
    /* box-shadow */
    -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
    -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
    box-shadow: rgba(0,0,0,0.8) 0 0 10px;
}

.loginButton {
    background-color: var(--PrimaryRed);
    color: white;
    border-radius: 10px 10px 0px 0px;
    padding: 0.5rem 0rem;
    font-size: 1rem;
    font-weight: 600;
}

.guestButton {
    background-color: var(--LightGray);
    color: var(--DarkGray);
    padding: 0.5rem 0rem;
    border-radius: 0px 0px 10px 10px;
    font-size: 1rem;
    font-weight: 600;
}

.buttonRow {
    display: flex;
}

@media only screen and (max-width: 800px) {
    .navBar {

        padding: 10px 5%;
    }
    .logo {
        max-height: 5rem;
    }
    .desktopButtons {
        display: none;
    }
    .hamburgerIconButton {
        display: block;
    } 
    .hamburgerMenu {
        display: flex;
    }
}
