.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
}

.page h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #333;
}

form {
    box-sizing: border-box;
}

.resetForm input[type="submit"] {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-radius: 0.25rem;
    background-color: #333;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.error {
    margin: 0;
    color: rgb(255, 70, 70);
    text-align: center;
}

.return {
    margin-top: 1rem;
    color: #333;
    text-decoration: none;
}

.success {
    color: #333;
}

.success a {
    color: #333;
    text-decoration: none;
}

.success a:hover {
    text-decoration: underline;
}