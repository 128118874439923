.createPostDiv {
    margin: 0 5rem;
}

.topBar {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.topBar h2 {
    text-align: center;
    margin: 0;
}

.topBar img {
    height: 35px;
    width: 35px;
    float: left;
}

.createPostDiv label {
    color: white;
}


.createPostDiv form {
    width: 35%;
    margin: auto;
}

.createPostDiv textarea {
    padding: 0.5rem;
}

.createPostDiv input[type="submit"] {
    font-size: 1.5rem;
    padding: 0.25rem 2.5rem;
    width: fit-content;
}

.createPostDiv form button img {
    max-width: 35px;
}

.createPostDiv form button {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 1rem;
    color: white;
}

.createPostDiv form button h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    margin: 0;
    margin-left: 1rem;
}

.createPostDiv form input[type="file"] {
    width: 75%;
}

.createPostDiv form p {
    color: white;
    margin: 0;
}



/* Image Preview */
.createPostDiv .previewDiv .previewContainer {
    position: relative;
}

.createPostDiv .previewImages {
    width: 100%;
    max-height: 320px;
    object-fit: cover;
    border: 4px solid var(--LightGray);
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1.2rem;
}

.createPostDiv .buttonBox {
    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
}

.createPostDiv .buttonBox button {
    margin-bottom: 0.5rem;
}
.createPostDiv .buttonBox img {
    height: 40px;
}

@media only screen and (max-width: 800px) {
    .createPostDiv {
        margin: 10px 5%;
    }
    .createPostDiv form {
        width: 100%;
    }

    .postButton {
        margin-bottom: 85px!important;
    }
}


.locationDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    & input[type="text"] {
        flex-grow: 2;
    }

    & select {
        max-width: fit-content;
    }
}


