.statsPageDiv {
    width: 80%;
    margin: auto;
}

.viewSelect {
    max-width: 300px;
}

@media only screen and (max-width: 800px) {
    .statsPageDiv {
        margin: 10px 3%;
        width: fit-content;
    }
}
