.manageUserForm {
    padding: 5px;
    border: 3px solid white;
    margin-bottom: 1rem;
    padding: 1rem;
}

.manageUserForm label {
    color: white;
    font-weight: 500;
}

.manageUserForm p {
    margin: 0;
    color: white;
}

h4 {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.manageUserForm button {
    color: white;
    border: 3px solid white;
    width: fit-content;
    margin: 0rem 0rem 1rem 0rem;
    padding: 1rem;
    border-radius: 25px;
    font-size: 1rem;
}

.manageUsers table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 4rem;
}
  
.manageUsers td, th {
    border: 1px solid var(--LightGray);
    text-align: left;
    padding: 8px;
}

.manageUsers td input, .manageUsers td select {
    margin: 0;
    width: 100%;
}
  
.manageUsers input {
    width: 100%;
}

.manageUsers button {
    color: white;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
}

.manageUsers img {
    height: 30px;
}

.manageUsers th:hover {
    cursor: pointer;
}

.save {
    background: var(--OkGreen);
}

.reset {
    background: rgb(19, 150, 238);
}

.delete {
    background: rgb(214, 60, 60);
}

/*Drop Down Menu*/
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
}

.dropdownContent button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
}

.dropdownContent button:hover {
    background-color: #ddd;
    border-radius: 10px;
}

.dropdown:hover .dropdownContent {display: block;}
