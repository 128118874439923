.profilePage {
    width: 600px;
    margin: auto;
}
.profilePage button {
    margin: 0;
    padding: 0;
    color: white;
}

.profilePage button img {
    height: 30px;
}

.profileBlockTop {
    display: flex;
    align-items: center;
}

.profileBlock h2 {
    margin: 0;
    margin-left: 1rem;
}

.profileBlock h3 {
    margin: 0;
    margin-top: 0.5rem;
}

.profilePhoto {
    object-fit: contain;
    width: 100px;
    height: 100px;
    background-color: var(--LightGray);
    margin-top: 1rem;
    border-radius: 50%;
    box-shadow: var(--DropShadow);
}

.profilePage .editButton {
    padding: 0.75rem 1.5rem;
    background-color: var(--PrimaryRed);
    margin: auto;
    border-radius: 0.75rem;
    font-size: 1rem;
}

@media only screen and (max-width: 800px) {
    .profilePage {
        margin: 10px 3%;
        width: fit-content;
    }
}
