.postImageBlock {
    position: relative;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
}

.swiper {
    overflow-x: visible;
    transition-property: transform;
    will-change: transform;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    transition-duration: 0.75s;
}

.postImage {
    width: 100%;
    object-fit: cover;   
    object-position: center;
    display: block;
    max-height: 70vh;
}

.postImage img {
    width: 100%;
    height: auto;
    display: block;
}

.postImageBlock video {
    width: 100%;
    height: 100%; /* Set the height to 100% to allow object-fit to work */
    object-fit: cover; /* This will make images/videos fill the container without distortion */
    object-position: center; /* Optional: Center the content within the container */
    display: block;
}

.postImageBlock .nextButton {
    position: absolute;
    color: white;
    box-sizing: border-box;
    bottom: 40%;
    right: 1%;
    cursor: pointer;
}
.postImageBlock .prevButton {
    position: absolute;
    color: white;
    box-sizing: border-box;
    bottom: 40%;
    left: 1%;
    cursor: pointer;
}

.nextButton img {
    width: 45px;
}

.prevButton img {
    width: 45px;
}

.fullscreen .postImageBlock {
    max-height: 90vh;
    border-radius: 20px;
}

img {
    cursor: pointer;
}
video {
    z-index: 5;
}
.videoBlock {
    position: relative;
    max-height: 70vh;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
}
.spinBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.imgParent {
    margin-top: auto;
    margin-bottom: auto;
}

.postImageBlockParent {
    background: #c4c4c4;
}

@media screen and (max-width: 768px) {
    .postImageBlock {
        max-height: 70vh;
    }
    .fullscreen .postImageBlock {
        max-height: 90vh;
    }    
}