.profileModal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    padding-bottom: 100px;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.profileModalContent {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
    max-height: 100%;
    overflow-y: auto;

}

.profileModalContent .infoBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.profileModalContent .infoBar h2 {
    margin: 1rem 0;
}

.profileModalContent h3 {
    margin: 0 0;
    font-size: 1.4rem;
    color: var(--DarkGray);
}

form label {
    font-size: 1rem;
}

.profileModal .profileModalContent .closeButton {
    color: var(--DarkGray);
    font-size: 1.2rem;
    font-weight: 700;
}

.profileModal .profileModalContent p {
    color: var(--DarkGray);
    margin: 0;
}

.profileModal .profileModalContent input {
    cursor: pointer;
}

.profileModalContent input[type="submit"] {
    padding:  10px 15px;
}

.profileModal select {
    width: 100%;
    padding: 0.5rem 0.75rem;
    margin: 0;
    margin-top: 0.2rem;
    margin-bottom: 1.2rem;
    display: inline-block;
    border:none;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: var(--LightGray);
    font-weight: 500;
    font-size: 1rem;
    font-family: acumin-pro,sans-serif;
    resize: none;
    box-shadow: var(--DropShadow);
}

@media only screen and (max-width: 800px) {
    .profileModalContent {
        width: 90%;
        box-sizing: border-box;
    }
}
