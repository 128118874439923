.filePage {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.filePage img {
    padding-top: 1rem;
}
.filePage a {
    cursor: pointer;
}
.fileList {
    font-size: 1.3rem;
    margin-top: 1.2rem;
}
.fileList a {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
}