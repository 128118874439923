td, th {
    padding: 8px;
}

tr:nth-child(even){background-color: #383838;}

th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color: white;
    border: none;
}

.statsTable {
    width: 100%;
}

table {
    width: 100%;
}

/* Options Box */
.optionsBox {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
}