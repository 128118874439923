.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.modalContent {
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    color:var(--DarkGray);
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
}

.logBox {
    max-height: 500px;
    overflow-y: scroll;
}
.topRow {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

@media only screen and (max-width: 800px) {
    .modalContent {
        width: 85%;
        box-sizing: border-box;
    }
}