.leftFrame {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-right: 1rem;
    align-items: flex-end;
}

* {box-sizing: border-box;}

.leftFrame .subMenu {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-end;
}

.leftFrame button {
    color: white;
    background: #7d7562;
    font-size: 1.5rem;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
}  

.leftFrame button img {
    max-width: 100%;
    max-height: 100%;
}

.leftFrame .noBackground {
    background: none!important;
}

.leftFrame select {
    color: white;
    background: #7d7562;
    font-size: 1.5rem;
    border-radius: 15px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0;
}

.leftFrame .textSearch {
    color: white;
    background: #7d7562;
    font-size: 1.5rem;
    border-radius: 15px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.26rem 0.5rem;
    margin-bottom: 0;
    width: 225px;
    flex-direction: row;
    margin-top: 0.2rem;
}

.leftFrame .textSearch img {
    max-width: 30px;
    max-height: 30px;
}

.leftFrame input {
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    color: white;
    font-size: 1.5rem;
    padding: 0;
}
.leftFrame input::placeholder {
    color: white;
    font-size: 1.5rem;
}

.leftFrame .textSearch button {
    height: 1.5rem;
}

.leftFrame .extraFilters {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 225px;
    align-items: flex-end;
    gap: 0.25rem;
    max-width: 100%;
}

.leftFrame .extraFilters select {
    width: 100%;
}

.hideOnDesktop {
    display: none!important;
}

@media screen and (max-width: 768px) {
    .leftFrame {
        row-gap: 0.25rem;
        padding-right: 0rem;
        margin-bottom: 0.5rem;
    }
    .leftFrame .subMenu{
        flex-direction: row;
        column-gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .leftFrame select {
        width: 100%;
    }
    .leftFrame .textSearch {
        width: 100%;
    }
    .leftFrame .extraFilters {
        display: none;
        width: 100%;
    }
    .hideOnDesktop {
        display: block!important;
    }
}